import { Action } from './action.interface';

interface Message {
    title: string;
    text: string;
    type: 'success' | 'error' | 'warning';
}

const DEFAULT_VALUE: Message[] = [];

/**
 * @param init initial status
 * @returns base
 */
export const InitMessageReducer = (init?: Message[]): Message[] => {
    return init || DEFAULT_VALUE;
};

/**
 * @param state status
 * @param action action
 * @returns base
 */
export const MessageReducer = (
    state: Message[],
    action: Action<Message>
): Message[] => {
    switch (action.type) {
        // this action use to set login information
        case 'MESSAGE':
            return [...state, action.payload];
        default:
            throw new Error();
    }
};
