import { COLORS } from '@live-happy/ui-kit';
import styled from 'styled-components';

export const StyledUserHeader = styled.header`
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1em;
    cursor: pointer;
`;

export const StyledButton = styled.button`
    background: ${COLORS.primary};
    color: ${COLORS.white};
    border: none;
    border-radius: 5px;
    padding: 0.5em 1em;
    font-size: 1em;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
        background: ${COLORS.primaryAlt};
        color: ${COLORS.primariyBooking};
    }
`;

export const StyledUserHeaderNotificationIconContainer = styled.div``;

export const StyledPageLoaderContainer = styled.section<{ full?: boolean }>`
    background: ${({ full }) =>
        full ? 'rgba(255, 255, 255, 0.5)' : COLORS.white};
    width: ${({ full }) => (full ? '100vw' : '100%')};
    height: ${({ full }) => (full ? '100vh' : '100%')};
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    position: ${({ full }) => (full ? 'fixed' : 'relative')};
`;

export const StyledPageTerm = styled.div`
    max-width: 98%;
    text-align: justify;
    font-family: Lato, sans-serif;
`;
export const StyledPageTermTitle = styled.div``;

export const StyledPageTermContainer = styled.div`
    overflow-x: hidden;
`;
