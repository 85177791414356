import { FC, Suspense, lazy, useEffect, useContext } from 'react';
import * as LH from '@live-happy/ui-kit';

import { HashRouter } from 'react-router-dom';

import { GlobalContextProvider } from './context';
import PageLoader from './components/PageLoader';

const ProtectedRoutes = lazy(() => import('./routes'));

/**
 * @returns component
 */
export const App: FC = () => {
    return (
        <GlobalContextProvider>
            <Suspense fallback={<PageLoader />}>
                <HashRouter>
                    <ProtectedRoutes />
                </HashRouter>
            </Suspense>
        </GlobalContextProvider>
    );
};
export default App;
