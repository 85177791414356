import { FC } from 'react';
import * as LH from '@live-happy/ui-kit';
import { StyledPageLoaderContainer } from './styles';

const PageLoader: FC<{ full?: boolean }> = ({ full }) => (
    <StyledPageLoaderContainer full={full}>
        <LH.Spinner />
    </StyledPageLoaderContainer>
);

export default PageLoader;
