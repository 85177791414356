import { Action } from './action.interface';

const DEFAULT_VALUE = !!localStorage.getItem('auth');

/**
 * @param init initial status
 * @returns base
 */
export const InitAuthReducer = (init?: boolean): boolean => {
    return init || DEFAULT_VALUE;
};

/**
 * @param state status
 * @param action action
 * @returns base
 */
export const AuthReducer = (
    state: boolean,
    action: Omit<Action, 'payload'>
): boolean => {
    switch (action.type) {
        // this action use to set login information
        case 'LOGIN':
            localStorage.setItem('auth', 'true');
            return true;
        // this action use to delete login information
        case 'LOGOUT':
            return DEFAULT_VALUE;
        default:
            throw new Error();
    }
};
