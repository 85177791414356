import { FC, createContext, useReducer } from 'react';

import * as LH from '@live-happy/ui-kit';

import { AuthReducer, InitAuthReducer } from './reducers/auth';
import { UserReducer, InitUserReducer } from './reducers/user';
import { MessageReducer, InitMessageReducer } from './reducers/messages';


const createContextValue = () => {
    // Reducer to control the Auth
    const [auth, dispatchAuth] = useReducer(
        AuthReducer,
        undefined, //uses default, then useEffect will set current status async
        InitAuthReducer
    );
    //user data
    const [user, dispatchUser] = useReducer(
        UserReducer,
        undefined, //uses default, then useEffect will set current status async
        InitUserReducer
    );
    //history of used codes
    // const [codes, dispatchCodes] = useReducer(
    //     MessageReducer,
    //     undefined, //uses default, then useEffect will set current status async
    //     InitMessageReducer
    // );


    //messages data
    const [messages, dispatchMessages] = useReducer(
        MessageReducer,
        undefined,
        InitMessageReducer
    );

    return {
        auth,
        dispatchAuth,
        user,
        dispatchUser,
        messages,
        dispatchMessages,
    };
};

type GlobalContextType = ReturnType<typeof createContextValue>;

export const GlobalContext = createContext<GlobalContextType>(
    {} as GlobalContextType
);

export const GlobalContextProvider: FC = ({ children }) => {
    const value = createContextValue();
    return (
        <GlobalContext.Provider value={value}>
            <LH.Toast messages={value.messages} />
            {children}
        </GlobalContext.Provider>
    );
};
