import { Action } from './action.interface';

const DEFAULT_VALUE = JSON.parse(localStorage.getItem('user') || '{}');

/**
 * @param init initial status
 * @returns base
 */
export const InitUserReducer = (): Record<string, unknown> => {
    return DEFAULT_VALUE;
};

/**
 * @param state status
 * @param action action
 * @returns base
 */
//FIXME: type
export const UserReducer = (state: boolean, action: Action): any => {
    switch (action.type) {
        // this action use to set login information
        case 'SET': {
            localStorage.setItem('user', JSON.stringify(action.payload));
            return action.payload;
        }

        default:
            throw new Error();
    }
};
